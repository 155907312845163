.container{
    max-width: 1240px;
    margin-right: auto;
    margin-left: auto;
    padding: 50px 20px;
    box-sizing: border-box;
    margin-top: 50px;
}
.container h2{
    text-align: center;
    margin: 0;
    font-weight: 600;
}
.main{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin-top: 40px;
}
.item{
    width: 100%;
    height: 100%;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    padding: 20px;
    box-sizing: border-box;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
}
.item img{
    height: 40px;
}
.item h3{
    margin: 0;
    margin-top: 20px;
    font-weight: 600;
}
.item p{
    margin: 0;
    margin-top: 5px;
    line-height: 150%;
    opacity: 0.7;
}
@media (max-width: 900px) {
    .main{
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (max-width: 550px) {
    .main{
        grid-template-columns: repeat(1, 1fr);
    }
}