.container{
    padding: 0 20px;
    box-sizing: border-box;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 100px;
    align-items: center;
}
.container img{
    height: 400px;
    width: 100%;
    border-radius: 20px;
    object-fit: cover;
}
.container h2{
    margin: 0;
    font-weight: 600;
}
.introText{
    margin: 0;
    font-weight: 500;
    opacity: 0.8;
}
.tags{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
}
.tag, .tag2, .tag3{
    height: 30px;
    width: fit-content;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    color: orange;
    background-color: rgba(255, 224, 165, 0.3);
    font-size: 14px;
    font-weight: 500;
}
.tag2{
    background-color: rgba(255, 0, 0, 0.2);
    color: red;
}
.tag3{
    background-color: rgba(0, 0, 255, 0.1);
    color: blue;
}
.mainText{
    margin: 0;
    margin-top: 20px;
    opacity: 0.7;
    line-height: 150%;
}
.container button{
    height: 55px;
    width: fit-content;
    padding: 0 50px;
    border-radius: 10px;
    background-color: var(--purple);
    color: #fff;
    border: 1px solid #fff;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    margin-top: 30px;
}
@media (max-width: 900px) {
    .container{
        grid-template-columns: repeat(1, 1fr);
        gap: 30px;
    }
}