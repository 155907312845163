.container{
    width: 100%;
    padding-top: 50px;
}
.mainContainer, .customerSupport{
    max-width: 600px;
    height: fit-content;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 10px;
    border: 0.5px solid #c8c8c8;
    background-color: #fff;
    margin-right: auto;
    margin-left: auto;
}
.mainContainer h3{
    margin: 0;
    font-size: 18px;
    font-weight: 500;
}
.top{
    display: flex;
    align-items: center;
    gap: 10px;
}
.count{
    height: 15px;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    background-color: #ff0000;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding: 0 6px;
}
.listContainer{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.item{
    padding: 10px 20px;
    box-sizing: border-box;
    border-radius: 10px;
    border: 0.5px solid #5dabf9;
    font-size: 16px;
    opacity: 0.8;
}
.customerSupport{
    display: grid;
    grid-template-columns: 50px 1fr 100px;
    gap: 15px;
    margin-top: 30px;
}
.customerSupport .image{
    height: 50px;
    width: 50px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: var(--purple);
    background-color: #eeeeee;
}
.customerSupport h3{
    margin: 0;
    font-size: 16px;
    font-weight: 500;
}
.customerSupport p{
    margin: 0;
    font-size: 14px;
    opacity: 0.8;
    margin-top: 10px;
    line-height: 130%;
}
.customerSupport button{
    height: 45px;
    width: 100%;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    background-color: var(--purple);
    border-radius: 7px;
    border: none;
}
@media (max-width: 500px) {
    .customerSupport{
        grid-template-columns: repeat(1, 1fr);
    }
}