.buttons{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    width: 350px;
    margin-top: 20px;
    animation: slideUp 1.5s ease forwards;
}
.button1, .button2{
    height: 40px;
    width: 100%;
    color: #fff;
    background-color: var(--purple);
    border: none;
    border-radius: 7px;
    font-size: 15px;
    font-weight: 600;
}
.button2{
    color: var(--purple);
    background-color: #fff;
    border: 1px solid var(--purple);
}
@media (max-width: 500px) {
    .buttons{
        width: calc(100% - 30px);
    }
}
@keyframes slideUp {
    0%{transform: translateY(100px); opacity: 0;}
    100%{transform: translateY(0px); opacity: 1;}
}