.banner{
    height: 200px;
    width: 100%;
    background-color: var(--purple);
    object-fit: cover;
}
.main{
    padding: 0 20px;
    box-sizing: border-box;
    position: relative;
    top: -75px;
}
.profilePicture{
    height: 150px;
    width: 150px;
    border-radius: 100px;
    background-color: #f5f5f5;
    border: 7px solid #fff;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    object-fit: cover;
}
.editContainer{
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    padding: 20px 0;
}
.editContainer input{
    display: none;
}
.editButton{
    color: var(--purple);
    cursor: pointer;
    font-weight: 500;
}
.details{
    width: 100%;
    text-align: center;
    margin-top: 10px;
}
.details h3{
    font-size: 22px;
    font-weight: 600;
    margin: 0;
}
.details p{
    margin: 0;
    font-weight: 500;
    opacity: 0.7;
}
.listContainer{
    max-width: 400px;
    height: fit-content;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 10px;
    border: 0.5px solid #d7d7d7;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
}
.listItem{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    align-items: center;
}
.itemTitle{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
.itemTitle div{
    height: 35px;
    width: 35px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: orange;
}
.itemTitle p{
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    opacity: 0.7;
}
.itemDetails{
    width: 100%;
    text-align: right;
    font-size: 15px;
    margin: 0;
}