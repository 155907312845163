.CardBox{
    width: calc(100%);
    margin-right: auto;
    margin-left: auto;
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0 10px 100px rgba(0,0,0,0.1);
    margin-bottom: 50px;
    padding: 20px 15px;
    box-sizing: border-box;
}
.CardBoxInput{
    height: 40px;
    width: 100%;
    border: 1px solid yellow;
    display: grid;
    grid-template-columns: 1fr 100px;
    border-radius: 7px;
    overflow: hidden;
}
.CardBoxInput input{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    font-size: 20px;
    padding-left: 10px;
    letter-spacing: 1px;
    border: none;
}
.CardBoxInput select{
    border: none;
    font-size: 18px;
    background-color: #fff;
    padding-left: 10px;
}
.CardBoxInput select:focus, .CardBoxInput input:focus   {
    outline: none;
}
.CardBox button{
    height: 45px;
    width: 100%;
    border-radius: 10px;
    background-color: #03255C;
    color: #fff;
    margin-top: 30px;
    font-size: 17px;
    font-weight: 500;
}