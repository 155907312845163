.Wa_section{
    width: 100vw;
    margin-top: 50px;
    padding-bottom: 50px;
}
.Wallets{
    height: fit-content;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 3px 5px rgb(204, 204, 204);
    border-radius: 3px;
    overflow: hidden;
    margin-bottom: 20px;
    box-sizing: border-box;
}
.WA_container{
    margin: 20px;
    overflow: hidden;
    display: grid;
}
.WA_container p{
    font-weight: 400;
    font-size: 18px;
    color: rgb(88, 88, 88);
    margin: 0;
    width: 100%;
    overflow: hidden;
    display: inline-flex;
    text-overflow: ellipsis;
}
.WA_container h4{
    margin-bottom: 15px;
    width: 100%;
    font-weight: 600;
    font-size: 18px;
    color: #000;
}
.company_wallet_container{
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #000;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.inline_box{
    height: 100%;
    width: 100%;
    /* background-color: pink; */
}