.container{
    width: 100%;
    margin-top: 40px;
}
.emptyDeposits{
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
}
.emptyDeposits img{
    height: 60px;
    margin-top: 40px;
}
.emptyDeposits h3{
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    margin-top: 15px;
}
.emptyDeposits p{
    opacity: 0.8;
    margin-top: 7px;
    font-size: 15px;
}