.container{
    max-width: 1240px;
    margin-right: auto;
    margin-left: auto;
    padding: 50px 20px;
}
.container h2{
    text-align: center;
    margin: 0;
    font-weight: 600;
}
.introText{
    text-align: center;
    margin-top: 10px;
    opacity: 0.8;
    font-weight: 500;
}
.mainContainer{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-top: 40px;
}
.item{
    height: 400px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    border-radius: 15px;
}
.canva{
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.2);
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}
.canva div{
    height: fit-content;
    width: 100%;
    background: rgba(255, 255, 255, 0.70);
    backdrop-filter: blur(7px);
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
}
.canva div h4{
    text-transform: capitalize;
    margin: 0;
    font-size: 18px;
    font-weight: 600;
}
.canva div p{
    margin: 0;
    margin-top: 4px;
    font-weight: 500;
    opacity: 0.8;
}
@media (max-width: 1000px) {
    .mainContainer{
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (max-width: 800px) {
    .mainContainer{
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (max-width: 550px) {
    .mainContainer{
        grid-template-columns: repeat(1, 1fr);
    }
}