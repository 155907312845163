.FAQpage{
    width: 100vw;
    background: linear-gradient(180deg, rgba(4, 161, 255, 0.06) 0%, rgba(4, 161, 255, 0) 100%);
}
.LineBoxx{
    height: 4px;
    width: 100px;
    background-color: red;
    margin-right: auto;
    margin-left: auto;
}
.Aligner{
    text-align: center;
    /* display: grid;
    align-items: center;
    justify-content: center; */
    /* background-color: black; */
}
.main_faq_container{
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
    padding: 50px 20px;
    box-sizing: border-box;
}
.FAQ_Item{
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 20px 6px rgb(226, 226, 226);
}
.FAQ_Item .FAQ_P1{
    font-size: 16px;
    font-weight: 600;
    color: black;
}
.FAQ_Item p{
    font-size: 16px;
    font-weight: 400;
    color: #000;
    margin-top: 10px;
}