@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
  
.Appp {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #fff;
  /* margin-top: 50px; */
  margin-bottom: 50px;
  box-shadow: 0 0 20px 6px rgb(226, 226, 226);
}
.Appp section{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
.headingg{
  font-family: 'Pacifico', cursive;
  font-size: 25px;
}
.containerr{
  /* height: 300px; */
  width: 100%;
}
.leftt{
  text-align: center;
}
.Appp input{
  padding-left: 5px;
  font-size: 20px;
  height: 36px;
  border-radius: 7px;
  background-color: #fff;
  border: 1px solid var(--purple);
  margin-left: auto;
  margin-right: auto;
}

.switchh{
  padding: 5px;
  height: 30px;
  width: 30px;
  background-color: var(--purple);
  border-radius: 20px;
  cursor: pointer;
  margin-right: 20px;
  margin-left: 20px;
  color: #fff;
}
.resultt{
  box-sizing: border-box;
  width: 100%;
  text-align: center;
}
.resultt button{
  width: 100px;
  height: 40px;
  font-weight: bold;
  font-size: 17px;
  background-color: #03255C;
  cursor: pointer;
  color: #fff;
  border: none;
  border-radius: 7px;
  margin-bottom: 10px;
}
.hhhp{
  font-size: 30px;
}
.hhhe{
  text-align: center;
  margin-bottom: 20px;
  margin-top: 10px;
}

.Dropdownlistm{
  font-weight: bold;
  font-size: 20px;
}

.Converter_Dropdown{
  height: 100vh;
  background-color: rgba(0,0,0,0.7);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  padding-top: 100px;
  justify-content: center;
  z-index: 200;
}

.Conveter_Main_dropDown{
  height: 80vh;
  background-color: #fff;
  border-radius: 10px;
  overflow-y: scroll;
}

.Converter_DropDown_Item{
  padding-top: 10px;
  width: 100%;
  margin-bottom: 10px;
}

.Converter_DropDown_Item p{
  color: #000;
  padding-left: 20px;
  margin: 0;
  font-size: 18px;
  z-index: 300;
  font-weight: bold;
  text-transform: uppercase;
}

.Currency_input_box_item{
  height: 35px;
  width: 120px;
  font-size: 17px;
  font-weight: bold;
  border-radius: 3px;
  text-transform: uppercase;
  border-color: var(--purple);
  background-color: #fff;
  color: #000;
}
.Currency_item_p{
  text-transform: uppercase;
}