.container{
    height: fit-content;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    border: 0.5px solid #dbdbdb;
    box-shadow: 0px 4px 10px rgba(0,0,0,0.07);
}
.top{
    display: flex;
    align-items: center;
    gap: 10px;
}
.icons{
    height: 35px;
    width: 35px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffc823;
    background-color: var(--purple);
    font-size: 16px;
}
.top p{
    margin: 0;
    font-size: 18px;
    font-weight: 500;
}
.bottom{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}
.title{
    font-size: 16px;
    margin: 0;
    opacity: 0.8;
}
.amount{
    margin: 0;
    text-align: right;
    font-size: 16px;
    font-weight: 600;
}