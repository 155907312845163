.Dashboard{
    height: 100vh;
    width: 100%;
    background-color: #f0f0f0;
    overflow: hidden;
    overflow-y: scroll;
    position: fixed;
    top: 0;
}
.DashboardHeader{
    height: 60px;
    width: 100%;
    display: flex;
}
.DH_1{
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;
}
.DH_icon{
    margin-right: 20px;
    margin-left: 20px;
}
.DH_icon img{
    height: 30px;
}
.DH_Greetings{
    font-size: 17px;
    font-size: 600;
    color: rgb(46, 46, 46);
}
.DH_2{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}
.DH_bellIcon, .DH_mailIcon{
    margin-right: 20px;
}
.DH_bellIcon img{
    height: 24px;
}
.Dashboard .Dashsection{
    padding-top: 50px;
    width: 90%;
    margin-left: 5%;
}
.DashSection2{
    padding-top: 50px;
    width: 90%;
    margin-left: 5%;
}
.Balance_box{
    height: 100px;
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgb(207, 207, 207);
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-right: 10px;
}
.BB_1{
    width: 70%;
    padding-left: 20px;
}
.BB_header{
    font-weight: 600;
    font-size: 18px;
    margin: 0;
}
.BB_balance{
    font-weight: 400;
    font-size: 18px;
    margin: 0;
    color: rgb(88, 88, 88);
    margin-top: 5px;
}
.BB_2{
    width: 30%;
    display: grid;
    align-items: center;
    justify-content: center;
}
.colored_box{
    height: 50px;
    width: 50px;
    border-radius: 3px;
    /* background-color: #03255C; */
    box-shadow: 0px 0px 10px rgb(173, 173, 173);
    display: grid;
    align-items: center;
    justify-content: center;
}

/************************* Deposit Section ***************************/
.Deposit_header{
    width: 100%;
    padding-bottom: 10px;
    margin-bottom: 30px;
}
.Deposit_header h3{
    margin: 0;
    font-weight: 600;
    opacity: 0.9;
    margin-bottom: 7px;
}
.DP_line{
    height: 3px;
    width: 100px;
    background-color: rgb(238, 238, 0);
    border-radius: 2px;
}
.Deposit_header p{
    margin: 0;
    margin-top: 10px;
    font-weight: 600;
    opacity: 0.7;
    letter-spacing: 1px;
}
.DepositBox{
    padding-bottom: 15px;
    width: 100%;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0px 0px 4px rgb(206, 206, 206);
    margin-bottom: 20px;
}
.Coin_form{
    width: 90%;
    margin-left: 5%;
    padding-top: 15px;
}
.Coin_form .CF_title{
    font-size: 17px;
    margin: 0;
    font-weight: 600;
    opacity: 0.8;
}
.Coin_form .CF_error{
    font-size: 17px;
    margin: 0;
    font-weight: 600;
    color: red;
}
.InputBox{
    height: 40px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid cyan;
    margin-top: 15px;
    overflow: hidden;
}
.InputBox input{
    height: 40px;
    width: 100%;
    border: 0;
    background-color: transparent;
    font-size: 16px;
    padding-left: 15px;
}
.InputBox input:focus{
    outline: 0;
}
.DB_bottom{
    width: 100%;
    margin-top: 20px;
    display: grid;
    justify-content: center;
}
.Invest_button{
    height: 40px;
    width: 100px;
    background-color: rgb(102, 173, 102);
    border-radius: 5px;
    box-shadow: 0px 0px 6px rgb(189, 189, 189);
    display: grid;
    align-items: center;
    justify-content: center;
}
.Invest_button p{
    color: #fff;
    font-weight: 600;
    margin: 0;
    padding: 0;
}
.DB_tradingWidget{
    display: grid;
    justify-content: center;
    height: 500px;
    width: 90%;
    margin-left: 5%;
}
.cld{
    color: rgb(224, 224, 0);
}

.scriptcontainer{
    background-color: #fff;
    padding-top: 20px;
    padding-right: 10px;
    padding-left: 10px;
    box-shadow: 0 0 5px #cacaca;
}
.sc_h3{
    margin: 0;
    opacity: 0.9;
    font-weight: 400;
}
.sc_p{
    margin: 0;
    opacity: 0.7;
}
.SecondWallet{
    width: 100%;
    padding-left: 15px;
}
.SecondWallet h3{
    margin: 0;
    padding: 0;
    color: #000;
}
.SecondWalletLine{
    height: 3px;
    width: 200px;
    background-color: yellow ;
    border-radius: 20px;
    margin-top: 10px;
}