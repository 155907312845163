@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');
/* *{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
} */
.html_body{
  display: grid;
  height: 100vh;
  width: 100%;
  /* position: absolute;
  top: 0; */
  place-items: center;
  background-color: #03255C;
  overflow-y: scroll;
  padding-top: 10vh;
  padding-bottom: 10vh;
}
::selection{
  background: #03255C;
  color: #fff;
  overflow-y: scroll;
}
.wrapper{
  overflow: hidden;
  max-width: 390px;
  background: #fff;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0px 15px 20px rgba(0,0,0,0.1);
}
.wrapper .title-text{
  display: flex;
  width: 200%;
}
.wrapper .title{
  width: 50%;
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  transition: all 0.6s cubic-bezier(0.68,-0.55,0.265,1.55);
}
.wrapper .slide-controls{
  position: relative;
  display: flex;
  height: 50px;
  width: 99%;
  overflow: hidden;
  margin: 30px 0 10px 0;
  justify-content: space-between;
  border: 1px solid lightgrey;
  border-radius: 5px;
}
.slide-controls .slide{
  height: 100%;
  width: 100%;
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
  z-index: 1;
  transition: all 0.6s ease;
}
.slide-controls label.signup{
  color: #000;
}
.slide-controls .slider-tab{
  position: absolute;
  height: 100%;
  width: 50%;
  left: 0;
  z-index: 0;
  border-radius: 5px;
  background-color: #03255C;
  transition: all 0.6s cubic-bezier(0.68,-0.55,0.265,1.55);
}
input[type="radio"]{
  display: none;
}
#signup:checked ~ .slider-tab{
  left: 50%;
}
#signup:checked ~ label.signup{
  color: rgb(255, 255, 255);
  cursor: default;
  user-select: none;
}
#signup:checked ~ label.login{
  color: #000;
}
#login:checked ~ label.signup{
  color: #000;
}
#login:checked ~ label.login{
  cursor: default;
  user-select: none;
}
.wrapper .form-container{
  width: 100%;
  overflow: hidden;
}
.form-container .form-inner{
  display: flex;
  width: 200%;
}
.form-container .form-inner form{
  width: 50%;
  transition: all 0.6s cubic-bezier(0.68,-0.55,0.265,1.55);
}
.form-inner form .field{
  height: 50px;
  width: 100%;
  margin-top: 20px;
}
.form-inner form .field input{
  height: 100%;
  width: 92%;
  outline: none;
  padding-left: 15px;
  border-radius: 5px;
  border: 1px solid lightgrey;
  border-bottom-width: 2px;
  font-size: 17px;
  transition: all 0.3s ease;
}
.SelectInputField{
  height: 100%;
  width: 98%;
  outline: none;
  padding-left: 15px;
  border-radius: 5px;
  border: 1px solid lightgrey;
  border-bottom-width: 2px;
  font-size: 17px;
  transition: all 0.3s ease;
}
.SelectInputField:focus{
  border-color: yellow;
}
.form-inner form .field input:focus{
  border-color: yellow;
  /* box-shadow: inset 0 0 3px #fb6aae; */
}
.form-inner form .field input::placeholder{
  color: #999;
  transition: all 0.3s ease;
}
form .field input:focus::placeholder{
  color: #b3b3b3;
}
.form-inner form .pass-link{
  margin-top: 5px;
}
.form-inner form .signup-link{
  text-align: center;
  margin-top: 30px;
}
.form-inner form .pass-link a,
.form-inner form .signup-link a{
  color: #371cce,;
  text-decoration: none;
}
.form-inner form .pass-link a:hover,
.form-inner form .signup-link a:hover{
  text-decoration: underline;
}
form .btn{
  height: 50px;
  width: 100%;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}
form .btn .btn-layer{
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;
  background-color: #03255C;
  border-radius: 5px;
  display: grid;
  align-items: center;
  justify-content: center;
}

.btn-layerr{
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;
  background-color: #03255C;
  border-radius: 5px;
  display: grid;
  align-items: center;
  justify-content: center;
}

form .btn:hover .btn-layer{
  left: 0;
}
.btn-layerr p:hover{
  color: yellow;
}
form .btn p{
  height: 100%;
  width: 100%;
  z-index: 1;
  position: relative;
  background: none;
  border: none;
  color: #fff;
  padding-left: 0;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  margin: 0;
  margin-top: 29px;
}
.Error_message_p{
  text-align: center;
  color: red;
  font-weight: 600;
}

.VerificationField{
  height: 100vh;
  width: 100%;
}
.MainVerificationField{
  height: 50vh;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20vh;
}
.MainVerificationField h1{
  font-weight: 600;
  color: #fff;
  font-size: 24px;
  margin: 0;
}
.MainVerificationField h3{
  font-weight: 500;
  color: #fff;
  opacity: 0.8;
  font-size: 20px;
  margin-top: 10px;
}
.verificationInput{
  height: 70px;
  width: 90%;
  border-radius: 10px;
  border: 0;
  letter-spacing: 20px;
  font-size: 25px;
  text-align: center;
}
.VerifyButton{
  height: 45px;
  width: 150px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 10px;
  display: grid;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.extra_verificationStuffs{
  padding-top: 20px;
  padding-bottom: 10px;
  width: 100%;
}

.Captcha{
  height: 70px;
  width: 100%;
  border-radius: 8px;
  border: 0.5px solid var(--purple);
  display: flex;
  gap: 10px;
}
.C1{
  width: 210px;
  height: 100%;
  display: flex;
  align-items: center;
}
.C2{
  width: 70px;
  height: 70px;
}
.C2 img{
  height: 70px;
  width: 70px;
}
.B1{
  height: 70px;
  width: 60px;
  border-radius: 3px;
  display: grid;
  justify-content: center;
  align-items: center;
}
.Checkbox_captcha{
  height: 30px;
  width: 30px;
  border-radius: 5px;
  border: 1px solid rgb(110, 110, 110);
  background-color: #f5f5f5;
}

.mainVerifyScreen{
  height: 100vh;
  width: 100%;
  background-color: #03255C;
}

.mainVerifyScreen h2{
  max-width: 80%;
  margin-right: auto;
  margin-left: auto;
  color: #fff;
  text-align: center;
}

.mainVerifyScreen h3{
  color: #fff;
  opacity: 0.8;
  text-align: center;
  margin-top: 20px;
}
.LoginPage_Modal{
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.452);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: grid;
  align-items: center;
  justify-content: center;
}
.LoginPage_Modal_Main{
  padding-bottom: 20px;
  width: 300px;
  background-color: #fff;
  border-radius: 15px;
}
.FaEx_Container{
  display: grid;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.LM_p_container{
  width: 260px;
  margin-left: 20px;
}
.LM_p_container p{
  margin: 0;
  padding: 0;
  text-align: center;
}
.LM_p_bottom_boxes{
  height: 40px;
  width: 260px;
  margin-left: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.LM_p_bottom_box1{
  height: 40px;
  width: 120px;
  background-color: #dbdbdb;
  border-radius: 7px;
  display: grid;
  align-items: center;
  justify-content: center;
  color: #000;
}
.LM_p_bottom_box2{
  height: 40px;
  width: 120px;
  background-color: #03255C;
  border-radius: 7px;
  display: grid;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.FG_back2{
  height: 45px;
  width: 150px;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
  border-radius: 7px;
  display: grid;
  align-items: center;
  justify-content: center;
  
}
.FG_back2 p{
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: #000;
}
.FGGG{
  position: absolute;
  bottom: 50px;
  display: grid;
  align-items: center;
  width: 100%;
}