.DepModal{
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    display: grid;
    align-items: center;
    justify-content: center;
}
.DepModalMain{
    width: 300px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #fff;
    border-radius: 10px;
}
.DepModalMainP{
    width: 260px;
    margin-left: 20px;
}
.DepModalMainP p{
    margin: 0;
    padding: 0;
    text-align: center;
    font-weight: bold;
}
.DepModalMainBtn{
    height: 40px;
    background-color: #dbdbdb;
    border-radius: 7px;
    width: 120px;
    margin-left: auto;
    margin-right: auto;
    color: #000;
    font-weight: bold;
    display: grid;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    cursor: pointer;
}
.WithdrawRequestsDiv{
    width: 100%;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 0px 5px rgb(207, 207, 207);
    padding: 15px 0;
    margin-right: auto;
    margin-left: auto;
}
@media (min-width: 500px) {
    .WithdrawRequestsDiv{
        width: 400px;
    }
}
.WithdrawalHistoryTitle{
    font-size: 18px;
    padding-left: 15px;
    margin: 0;
    font-weight: bold;
    padding-bottom: 10px;
}
.WithItem{
    width: 90%;
    border: 1px solid yellow;
    border-radius: 7px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10px;
    padding: 10px 0;
    overflow: hidden;
}
.WithItem p{
    font-size: 17px;
    padding-left: 10px;
    margin: 0;
    font-weight: bold;
    margin-bottom: 5px;
    font-family: sans-serif;
}
.WallId{
    overflow: hidden;
    width: 10%;
    word-break: break-all;
}
.WithItem span{
    font-weight: 400;
}
.DepositHistory{
    height: fit-content;
    max-width: 730px;
    background-color: #fff;
    margin-right: auto;
    margin-left: auto;
    box-shadow: 0px 0px 4px rgb(206, 206, 206);
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 50px;
    margin-top: 50px;
}

.DepositHistory h2{
    font-size: 18px;
    margin: 0;
}
.DepositHistoryHeader{
    width: 100%;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
.DepositHistoryHeader p{
    margin: 0;
    font-size: 15px;
    padding-bottom: 10px;
    opacity: 0.5;
}
.DepositHistoryItem{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    box-sizing: border-box;
    padding: 15px 10px;
    margin: 10px 0;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}
.DepoH_amount{
    color: rgb(19, 255, 109);
}
@media (max-width: 750px) {
    .DepositHistoryHeader, .DepositHistoryItem{
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .DepoH_status{
        display: none;
    }
    .DepositHistoryHeader p{
        font-size: 14px;
    }
}