.container{
    max-width: 1040px;
    margin-right: auto;
    margin-left: auto;
    padding: 50px 20px;
    box-sizing: border-box;
}
.container h2{
    text-align: center;
    margin: 0;
    font-weight: 600;
}
.introText{
    text-align: center;
    margin-top: 10px;
    opacity: 0.8;
    font-weight: 500;
}
.plansContainer{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
    margin-top: 50px;
}
.item{
    padding: 20px;
    box-sizing: border-box;
    border-radius: 20px;
    border: 0.5px solid rgba(0, 0, 0, 0.2);
    width: 100%;
}
.image{
    height: 50px;
    width: 50px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    border: 0.5px solid #b9b9b9;
    font-size: 25px;
}
.top{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    align-items: center;
}
.title{
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    opacity: 0.8;
    margin-top: 10px;
}
.amount{
    margin: 0;
    margin-top: 15px;
}
.list{
    margin-top: 20px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-top: 0.5px solid rgba(0, 0, 0, 0.3);
}
.listItem{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
}
.check{
    height: 30px;
    color: var(--purple);
}
.listItem p{
    margin: 0;
    font-weight: 500;
}
.item button{
    height: 55px;
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
    color: var(--purple);
    border: 1px solid var(--purple);
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    margin-top: 30px;
}
@media (max-width: 600px) {
    .plansContainer{
        grid-template-columns: repeat(1, 1fr);
    }
}