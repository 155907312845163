.Terms_container{
    width: 100vw;
    background: linear-gradient(180deg, rgba(4, 161, 255, 0.06) 0%, rgba(4, 161, 255, 0) 100%);
}
.Terms_container h2{
    text-align: center;
}
.terms_and_conditions_paragraphs{
    max-width: 1240px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 20px;
    box-sizing: border-box;
}
.main_terms_container{
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
    padding: 50px 20px;
    box-sizing: border-box;
}