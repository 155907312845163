.container{
    width: 100vw;
    min-height: 100vh;
    background: linear-gradient(180deg, rgba(4, 161, 255, 0.06) 0%, rgba(4, 161, 255, 0) 100%);
}
.mainContainer{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 20px;
    box-sizing: border-box;
    gap: 50px;
    overflow: hidden;
}
.bannerImg{
    min-height: 700px;
    width: 100%;
    box-sizing: border-box;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 20px;
}
@media (max-width: 1000px) {
    .mainContainer{
        grid-template-columns: repeat(1, 1fr);
    }
    .bannerImg{
        display: none;
    }
}
.mainForm{
    max-width: 450px;
    margin-right: auto;
    margin-left: auto;
    padding: 50px 0;
}
.mainForm button{
    height: 55px;
    width: 100%;
    background-color: var(--purple);
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    border-radius: 10px;
    border: none;
    margin-top: 40px;
}
.mainForm h2{
    margin: 0;
    font-weight: 600;
    text-align: center;
}
.mainForm .introText{
    margin: 0;
    font-size: 16px;
    text-align: center;
    opacity: 0.8;
    margin-top: 5px;
}
.inputContainer{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    margin-top: 50px;
}
.inputContainer input, .inputContainer select{
    height: 40px;
    padding: 0 10px;
    box-sizing: border-box;
    border: 0.5px solid var(--purple);
    border-radius: 7px;
}
.verifyScreen{
    padding: 50px 20px;
    box-sizing: border-box;
    text-align: center;
}
.check{
    height: 80px;
    width: 80px;
    background-color: #3FD885;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    color: #fff;
    margin-right: auto;
    margin-left: auto;
    font-size: 40px;
    border: 10px solid rgba(193, 255, 221, 0.6);
    margin-bottom: 20px;
}
.backtologin, .reset{
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    margin-top: 20px;
    height: 50px;
    padding: 0 20px;
    background-color: #fff;
    border-radius: 30px;
    border: 0.5px solid var(--purple);
    color: var(--purple);
}
.reset{
    color: #fff;
    background-color: var(--purple);
}
.switchText{
    font-size: 16px;
    font-weight: 500;
    margin-top: 20px;
    cursor: pointer;
}
.switchText span{
    color: var(--purple);
}
.doneContainer{
    text-align: center;
    max-width: 400px;
    margin-right: auto;
    margin-left: auto;
    padding: 50px 0;
}
.buttonsContainer{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}
.doneContainer input{
    height: 40px;
    padding: 0 10px;
    box-sizing: border-box;
    border: 0.5px solid var(--purple);
    border-radius: 7px;
    width: 100%;
    margin-top: 30px;
}
