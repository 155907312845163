.DepModal{
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    display: grid;
    align-items: center;
    justify-content: center;
}
.DepModalMain{
    width: 300px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #fff;
    border-radius: 10px;
}
.DepModalMainP{
    width: 260px;
    margin-left: 20px;
}
.DepModalMainP p{
    margin: 0;
    padding: 0;
    text-align: center;
    font-weight: bold;
}
.DepModalMainBtn{
    height: 40px;
    background-color: #dbdbdb;
    border-radius: 7px;
    width: 120px;
    margin-left: auto;
    margin-right: auto;
    color: #000;
    font-weight: bold;
    display: grid;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    cursor: pointer;
}
.DepositSelect{
    width: 100%;
    height: 40px;
    border: 1px solid #fff000;
    padding-left: 15px;
    font-size: 16px;
    margin-top: 15px;
    border-radius: 5px;
    opacity: 0.9;
    background-color: #fff;
}
.DepositHistory{
    height: fit-content;
    max-width: 730px;
    background-color: #fff;
    margin-right: auto;
    margin-left: auto;
    box-shadow: 0px 0px 4px rgb(206, 206, 206);
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 50px;
    margin-top: 50px;
}

.DepositHistory h2{
    font-size: 18px;
    margin: 0;
}
.DepositHistoryHeader{
    width: 100%;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
.DepositHistoryHeader p{
    margin: 0;
    font-size: 15px;
    padding-bottom: 10px;
    opacity: 0.5;
}
.DepositHistoryItem{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    box-sizing: border-box;
    padding: 15px 10px;
    margin: 10px 0;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    cursor: pointer;
}
.DepoH_amount_title{
    padding-left: 10px;
}
.DepoH_amount{
    color: rgb(19, 255, 109);
}
.dep_history_extra{
    font-size: 14px;
    opacity: 0.5;
    margin-top: 10px;
}
@media (max-width: 750px) {
    .DepositHistoryHeader, .DepositHistoryItem{
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .DepoH_status{
        display: none;
    }
    .DepositHistoryHeader p{
        font-size: 14px;
    }
}
.DepositHistoryItem{
    text-transform: capitalize;
}

/******** ShowDepositScreen *********/
.ShowDepositScreen{
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
    position: fixed;
    top: 0;
    left: 0;
}
.showDepCancle{
    height: 30px;
    font-size: 20px;
    color: #fff;
    width: 400px;
    display: flex;
    flex-direction: row-reverse;
    margin-right: auto;
    margin-left: auto;
    margin-top: 50px;
    cursor: pointer;
}
.mainShowDeposit{
    width: 400px;
    background-color: #fff;
    border-radius: 3px;
    margin-right: auto;
    margin-left: auto;
}
@media (max-width: 450px) {
    .showDepCancle, .mainShowDeposit{
        width: calc(100vw - 30px);
    }
}
.mainShowDepositTop{
    height: fit-content;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px 15px;
    box-sizing: border-box;
}
.showDepTitle{
    font-size: 16px;
    font-weight: 500;
    font-family: sans-serif;
    color: #000;
    text-align: center;
}
.showDepMain{
    text-align: center;
    padding-top: 10px;
}
.sdm_1{
    font-size: 14px;
    opacity: 0.7;
    margin-bottom: 5px;
}
.sdm_2{
    font-size: 22px;
    font-family: sans-serif;
    font-weight: 500;
    margin: 0;
}
.sdm_2 span{
    font-size: 15px;
    font-weight: 400;
    opacity: 0.8;
}
.sdm_status, .sdm_status2, .sdm_status3{
    display: flex;
    flex-direction: row;
    gap: 10px;
    font-size: 14px;
    color: rgb(53, 241, 53);
    justify-content: center;
    margin-top: 4px;
    align-items: center;
}
.sdm_status2{
    color: rgb(82, 82, 255);
}
.sdm_status3{
    color: red;
}
.sdm_status p, .sdm_status2 p, .sdm_status3 p{
    margin: 0;
    margin-top: 2px;
}

.sdm_extraStatus{
    text-align: center;
    font-size: 12px;
    opacity: 0.5;
    margin-top: 10px;
    line-height: 150%;
}
.sdm_mainDetails{
    height: fit-content;
    width: 100%;
    padding: 20px 15px;
    box-sizing: border-box;
    display: grid;
    gap: 20px;
}
.sdm_item{
    width: 100%;
    display: grid;
    grid-template-columns: 130px 1fr;
    align-items: center;
    overflow: hidden;
    word-wrap: break-word;
    word-break: break-all;
}
.sdm_item_1{
    color: #000;
    opacity: 0.4;
    font-size: 14px;
}
.sdm_item_2{
    color: #000;
    font-size: 14px;
    display: flex;
    justify-content: flex-end;
    text-align: right;
    text-justify: inter-word;
}
.sdm_item_2 p{
    margin: 0;
    word-wrap: break-word;
    word-break: break-all;
    text-align-last: left;
}
.sdm_capitialize{
    text-transform: capitalize;
}
