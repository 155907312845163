.container{
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: grid;
    grid-template-columns: 300px 1fr;
}
.sideDrawer{
    height: 100%;
    width: 100%;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
}
.logo{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    font-weight: 600;
    color: var(--purple);
    cursor: pointer;
}
.logo img{
    height: 30px;
}
.user{
    height: fit-content;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    margin-top: 30px;
    display: flex;
    gap: 10px;
    align-items: center;
    background-color: #fafafa;
}
.user img{
    height: 40px;
    width: 40px;
    border-radius: 20px;
    background-color: var(--purple);
    object-fit: cover;
}
.user h4{
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}
.linksContainer{
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}
.plansLink{
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.link, .LinkDropDown, .activeLink{
    height: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    cursor: pointer;
    padding: 0 20px;
    box-sizing: border-box;
    color: var(--purple);
    border-radius: 10px;
}
.activeLink{
    background-color: var(--purple);
    color: #fff;
}
.link:hover{
    background-color: rgba(3, 37, 92, 0.3);
    color: #fff;
}
.LinkDropDown{
    position: relative;
}
.dropDownIcon{
    position: absolute;
    right: 10px;
    height: 25px;
    width: 25px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #f5f5f5;
    color: var(--purple);
    font-size: 14px;
}
.logout, .Mobilelogout{
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    color: red;
    background-color: #f7f7f7;
    height: 50px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 20px;
    box-sizing: border-box;
    cursor: pointer;
}
.Mobilelogout{
    position: relative;
    border-radius: 10px;
}
.mobileProfile{
    height: fit-content;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    background-color: #f5f5f5;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 40px 1fr;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}
.mobileProfile img{
    height: 40px;
    width: 40px;
    border-radius: 20px;
    object-fit: cover;
}
.mobileProfile p{
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}






.children{
    min-height: 100vh;
    width: 100%;
    background: linear-gradient(180deg, rgba(4, 161, 255, 0.06) 0%, rgba(4, 161, 255, 0) 100%);
    overflow-y: scroll;
    padding: 20px;
    box-sizing: border-box;
}
.isProfile{
    min-height: 100vh;
    width: 100%;
    background: linear-gradient(180deg, rgba(4, 161, 255, 0.06) 0%, rgba(4, 161, 255, 0) 100%);
    overflow-y: scroll;
}
.top{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 40px;
}
.top h2{
    margin: 0;
}
.flexItems{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
}
.flexItems img{
    height: 50px;
    width: 50px;
    border-radius: 30px;
    background-color: var(--purple);
    object-fit: cover;
    cursor: pointer;
}
.flexItems div, .mobileTop div{
    height: 35px;
    width: 35px;
    border-radius: 30px;
    border: 0.5px solid var(--purple);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--purple);
    background-color: #fff;
    cursor: pointer;
    display: none;
}
.mobileTop div{
    display: flex;
}
@media (max-width: 1000px) {
    .flexItems div{
        display: flex;
    }
    .container{
        grid-template-columns: repeat(1, 1fr);
    }
    .sideDrawer{
        display: none;
    }
}
.MobileSideDrawer{
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    display: grid;
    grid-template-columns: 1fr 300px;
}
.closeSection{
    height: 100vh;
    width: 100%;
}
.mainMobileDrawer{
    height: 100vh;
    width: 100%;
    background-color: #fff;
    z-index: 1000;
    padding: 30px 20px;
    box-sizing: border-box;
    animation: slideIn 0.5s ease forwards;
}
.mobileTop{
    display: grid;
    grid-template-columns: 1fr 35px;
    gap: 10px;
    align-items: center;
}
.mobileTop img{
    height: 30px;
}

@keyframes slideIn {
    0%{transform: translateX(300px); opacity: 0.5;}
    100%{transform: translateX(0); opacity: 1;}
}