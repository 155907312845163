.container{
    width: 100%;
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}
.item{
    height: fit-content;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #dddddd;
    overflow: hidden;
    box-sizing: border-box;
    padding: 20px;
    box-sizing: border-box;
    min-height: 130px;
}
.topItem{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    font-weight: 500;
}
.topItem img{
    height: 40px;
    width: 40px;
    border-radius: 20px;
    object-fit: cover;
    background-color: #f5f5f5;
}
.content{
    display: grid;
    grid-template-columns: 1fr 40px;
    gap: 20px;
    margin-top: 20px;
}
.content p{
    font-size: 16px;
    font-weight: 500;
    opacity: 0.7;
    width: 100%;
    overflow: hidden;
}
.copy{
    height: 40px;
    width: 40px;
    border-radius: 30px;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
@media (max-width: 900px) {
    .container{
        grid-template-columns: 1fr;
    }
}