.container{
    width: 100vw;
    background: linear-gradient(180deg, rgba(4, 161, 255, 0.06) 0%, rgba(4, 161, 255, 0) 100%);
}
.mainContainer{
    max-width: 1240px;
    margin-right: auto;
    margin-left: auto;
    display: grid;
    padding: 50px 20px;
    grid-template-columns: repeat(2, 1fr);
    gap: 100px;
    box-sizing: border-box;
    align-items: center;
}
.contents{
    display: flex;
    flex-direction: column;
    font-family: var(--graphik);
}
.contents h1{
    font-size: 60px;
    /* line-height: 150%; */
    font-weight: 600;
    margin: 0;
}
.contents p{
    opacity: 0.8;
    line-height: 150%;
    font-size: 16px;
    margin: 0;
    margin-top: 10px;
}
.contents button{
    height: 55px;
    width: fit-content;
    padding: 0 50px;
    border-radius: 10px;
    background-color: var(--purple);
    color: #fff;
    border: 1px solid #fff;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    margin-top: 30px;
}
.moreInfo{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    margin-top: 60px;
}
.item{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
.item h3{
    margin: 0;
}
.item h3 span{
    font-size: 18px;
    font-weight: 600;
    opacity: 0.7;
}
.imagesContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: -60px;
}
.imagesContainer img{
    height: 40px;
    width: 40px;
    border-radius: 40px;
    border: 5px solid #fff;
    object-fit: cover;
}
.imagesContainer img:nth-child(2){
    transform: translateX(-30px);
}
.imagesContainer img:nth-child(3){
    transform: translateX(-60px);
}

.display_images{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
}
.display_images img{
    max-width: 400px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
}
.display_images img:nth-child(1){
    transform: translateX(-60px);
}
.display_images img:nth-child(2){
    transform: translateX(-30px);
}
.mobileTransactions{
    text-align: center;
    display: none;
    flex-direction: column;
    gap: 20px;
}
.transactions{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.Item{
    height: fit-content;
    max-width: 350px;
    background-color: #fff;
    border-radius: 10px;
    border: 0.5px solid #b9b9b9;
    padding: 10px;
    display: grid;
    grid-template-columns: 40px 1fr;
    gap: 10px;
    align-items: center;
    overflow: hidden;
    box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.11);
    box-sizing: border-box;
}
.Item_image, .Item_image2{
    height: 40px;
    width: 40px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f79413;
    color: #fff;
}
.Item_image2{
    background-color: cyan;
}
.Item_details_main{
    display: flex;
    gap: 10px;
    align-items: center;
}
.Item_details_main div{
    height: 4px;
    width: 4px;
    border-radius: 2px;
    background-color: #000;
}
.Item_details_main h3{
    margin: 0;
    font-size: 16px;
    font-weight: 500;
}
.Item_details_main p{
    margin: 0;
    opacity: 0.7;
}
.wallets{
    margin: 0;
    margin-top: 5px;
    font-weight: 500;
    opacity: 0.8;
}

@media (max-width: 800px) {
    .mainContainer{
        grid-template-columns: repeat(1, 1fr);
        gap: 50px;
    }
    .contents h1{
        font-size: 45px;
    }
    .display_images{
        width: 100%;
    }
    .display_images img:nth-child(1){
        transform: translateX(0px);
    }
    .display_images img:nth-child(2){
        transform: translateX(0px);
    }
    .display_images img{
        max-width: 100%;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        border-radius: 25px;
    }
    .desktopTransactions{
        display: none;
    }
    .Item{
        width: 100%;
    }
    .mobileTransactions{
        display: flex;
    }
}