.container{
    height: 100px;
    width: 100vw;
    padding: 0 50px;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
}
.top{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
.top img{
    height: 30px;
}
.top h2{
    margin: 0;
    font-size: 20px;
    color: var(--purple);
}
.links_container{
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 50px;
}
.login{
    height: 45px;
    width: fit-content;
    padding: 0 30px;
    border-radius: 30px;
    background-color: #fff;
    color: var(--purple);
    border: 1px solid var(--purple);
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
}
.register{
    height: 45px;
    width: fit-content;
    padding: 0 30px;
    border-radius: 30px;
    background-color: var(--purple);
    color: #fff;
    border: 1px solid #fff;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
}
.main_links_container{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 50px;
    text-decoration: none;
    color: var(--purple);
}
.main_links_container a{
    color: var(--purple);
}
.links_container p{
    font-size: 16px;
    margin: 0;
    font-weight: 500;
    cursor: pointer;
}
.buttonsContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}
.hamburgerIcon{
    height: 45px;
    width: 45px;
    border-radius: 30px;
    background-color: #fff;
    color: var(--purple);
    border: 0.5px solid var(--purple);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    display: none;
    cursor: pointer;
}
@media (max-width: 950px) {
    .main_links_container, .buttonsContainer{
        display: none;
    }
    .hamburgerIcon{
        display: flex;
    }
    .container{
        padding: 0 20px;
    }
}

.sideDrawer{
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    z-index: 2000;
    display: grid;
    grid-template-columns: 1fr 300px;
}
.sideDrawerMain{
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
    animation: slidein 0.5s ease forwards;
}
.drawerHeader{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
}
.sideDrawerLinks{
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 50px;
    text-align: center;
}
.sideDrawerLinks p{
    font-size: 16px;
    margin: 0;
    font-weight: 500;
    cursor: pointer;
    color: var(--purple)
}
.sideDrawerLinks a{
    color: var(--purple);
}
.sideDrawerButtons{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 50px;
}
.sideDrawerButtons .login, .register{
    width: 100%;
}
@keyframes slidein {
    0%{transform: translateX(300px);}
    100%{transform: translateX(0px);}
}