.RComponent{
    margin-top: 30px;
    width: 100%;
    padding-bottom: 50px;
}
.SecondWallet{
    width: 100%;
}
.SecondWallet h3{
    margin: 0;
    padding: 0;
    color: #000000;
}
.SecondWalletLine{
    height: 3px;
    width: 200px;
    background-color: #ffff00;
    border-radius: 20px;
    margin-top: 10px;
}
.RC_Boxes{
    width: 100%;
    height: 300px;
    margin-top: 50px;
}
@media (max-width: 600px) {
    .RC_Boxes{
        display: inline-block;
    }
}
.RC_Box1{
    max-width: 400px;
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    padding: 15px;
    box-sizing: border-box;
    margin-bottom: 30px;
}
.RC_Box2{
    width: 100%;
    background-color: #fff;
    border-radius: 15px;
    /* margin-right: auto; */
    border: 1px solid #dfdfdf;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
}
.RC_Box3{
    width: 100%;
    background-color: #fff;
    border-radius: 15px;
    /* margin-right: auto; */
    box-shadow: 0 0 5px #cacaca;
    padding-top: 20px;
    padding-bottom: 20px;
}
.RC_p{
    font-size: 16px;
    margin: 0;
    padding: 0;
    margin-left: 20px;
}
.RC_ID{
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    padding: 0;
    margin-left: 20px;
    padding-top: 7px;
}
.RC_Box2 h1{
    font-weight: bold;
}
.RC_Box2 p{
    font-weight: bold;
    font-size: 20px;
}
.RefScreenError{
    padding-top: 30vh;
    width: 100vw;
    text-align: center;
}
.RefScreenError p{
    font-size: 20px;
    font-weight: bold;
    color: #000;
    padding-top: 10px;
}
.RefScreenError span{
    font-size: 20px;
}
.IntroDetailsRC{
    font-size: 15px;
    opacity: 0.8;
    padding-top: 10px;
    padding-bottom: 10px;
}
.IntroDetailsRC2{
    font-size: 15px;
    opacity: 0.8;
    padding-top: 10px;
}
.R_Box_Users{
    max-width: 400px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 5px #cacaca;
    margin-top: 30px;
    padding: 20px 0;
}
.RUsers{
    height: 70px;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    display: grid;
    grid-template-columns: 65px 1fr;
    align-items: center;
    margin-bottom: 10px;
    border: 1px solid yellow;
    border-radius: 10px;
}
.RuserTitle{
    height: 45px;
    width: 45px;
    border-radius: 30px;
    margin-left: 10px;
    background-color: #03255C;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 20px;
    display: grid;
    align-items: center;
    justify-content: center;
}
.RuserDetails{
    height: fit-content;
    width: 100%;
}
.RUsn{
    font-weight: bold;
    font-size: 18px;
    margin: 0%;
    padding: 0;
}
.RSecond{
    font-size: 18px;
    margin: 0;
    padding: 0;
    font-weight: bold;
    opacity: 0.6;
}