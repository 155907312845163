.mainContainer{
    padding: 50px 20px;
    box-sizing: border-box;
}
.container{
    height: fit-content;
    padding: 50px 50px;
    box-sizing: border-box;
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 20px;
    box-shadow: 0 4px 10px rgba(0,0,0,0.1);
}
.rocket_container{
    height: 100px;
    width: 100px;
    margin-right: auto;
    margin-left: auto;
    background-color: #efefef;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.rocket_container img{
    height: 80px;
    margin-top: 40px;
    animation: rocket 2s
    ease-out
    0s
    alternate
    infinite
    none
    running;
}

@keyframes rocket{
    0%{
        transform: translateX(-30px);
        transform: translateY(-30px);
    }
    100%{
        transform: translateX(0px);
        transform: translateY(0px);
    }
}
.container h2{
    margin: 0;
    margin-top: 20px;
    font-weight: 600;
    text-align: center;
}
.introText{
    text-align: center;
    margin-top: 5px;
    opacity: 0.8;
    font-weight: 500;
}
.main{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
    column-gap: 50px;
    margin-top: 30px;
}
.title{
    font-weight: 500;
    opacity: 0.8;
}
@media (max-width: 700px) {
    .container{
        padding: 20px 20px;
    }
}
@media (max-width: 350px) {
    .main{
        grid-template-columns: repeat(1, 1fr);
    }
}