.PortfolioPage{
    padding-bottom: 50px;
    width: 100%;
    padding-top: 40px;
}
.PortHeader{
    font-size: 18px;
    font-weight: 600;
    color: #000;
    margin-bottom: 30px;
}

.PortfolioContainer{
    width: 100%;
    padding-top: 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}

.PortFolioItem{
    padding-bottom: 10px;
    /* height: fit-content; */
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0 0px 7px rgba(0,0,0,0.1);
    overflow: hidden;
    cursor: pointer;
}
.PFI1{
    display: grid;
    grid-template-columns: 1fr 50px;
}
.PFI_Main{
    width: 100%;
    padding-top: 10px;
    box-sizing: border-box;
    display: grid;
    justify-content: flex-end;
    display: grid;
    align-items: center;
}
.PortFolioItem:hover{
    background-color: #f9f9f9;
}
.Portf_flex_title{
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 15px;
}
.Port_flex_details{
    font-weight: 600;
    font-size: 18px;
    margin: 0;
    color: #000;
}
.PortfolioTitle{
    font-weight: 600;
    font-size: 18px;
    margin: 0;
    color: #000;
}
.PortfolioBalance{
    padding-top: 5px;
    font-weight: 400;
    font-size: 18px;
    color: rgb(88, 88, 88);
}
.PortFolioBottom{
    padding-top: 10px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* padding-left: 15px; */
}
.PortFolioBottom .portActivep{
    font-weight: bold;
    font-size: 17px;
    color: #000;
    opacity: 0.5;
    font-weight: bold;
    margin: 0;
}
.showref_div{
    display: grid;
    grid-template-columns: 1fr 50px;
}
.Active{
    color: #00DD55;
    opacity: 1;
    font-weight: bold;
    font-size: 17px;
    font-weight: bold;
}
.Expired{
    color: #ff0000;
    opacity: 1;
    font-weight: bold;
    font-size: 17px;
    font-weight: bold;
}
.PortConverter{
    width: 600px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.PP_referall{
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0px 7px rgba(0,0,0,0.1);
    margin-top: 50px;
    padding: 15px;
    box-sizing: border-box;
    margin-bottom: 50px;
}
.PP_title{
    font-size: 20px;
    font-weight: bold;
    color: #000;
    margin-bottom: 10px;
    padding-top: 10px;
}
.PP_main{
    font-size: 17px;
    margin-bottom: 20px;
}
.PP_mainCode{
    font-size: 20px;
    font-weight: bold;
    color: #000;
    padding-bottom: 20px;
}
.IntroTitleRC{
    padding-left: 20px;
    padding-top: 10px;
    font-size: 20px;
    font-weight: bold;
}
.BB_flexer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: 30px;
}
@media (max-width: 1200px) {
    .PortfolioContainer{
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (max-width: 800px) {
    .PortfolioContainer{
        grid-template-columns: repeat(2, 1fr);
    }
    .PortFolioItem{
        width: 100%;
        margin: 0;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 20px;
    }
    .PortConverter{
        width: 100%;
        margin-left: -15px;
    }
    .IntroTitleRC{
        font-size: 15px;
    }
    .BB_flexer{
        display: block;
        width: 100%;
        column-gap: 0;
    }
    .roi_image{
        display: none;
    }
    .PP_referall{
        width: calc(100vw - 60px);
        margin-right: auto;
        margin-left: auto;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 0px 7px rgba(0,0,0,0.1);
        margin-top: 50px;
        padding: 15px;
        margin-bottom: 50px;
    }
    .port_bottom_gct{
        display: none;
    }
}
@media (max-width: 600px) {
    .PortfolioContainer{
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
    }
}
.PFI_Title{
    font-weight: 600;
    font-size: 18px;
    margin: 0;
    color: #000;
    padding-top: 15px;
}
.PFI_value{
    padding-top: 5px;
    font-weight: 400;
    font-size: 18px;
    color: rgb(88, 88, 88);
    margin-bottom: 10px;
}
.pp_flex_end{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.SecondWalletLine22{
    height: 3px;
    width: 200px;
    background-color: yellow ;
    border-radius: 20px;
    margin-top: 0px;
    margin-bottom: 30px;
    margin-left: 15px;
}
.roi_image{
    width: 100%;
    text-align: center;
}
.roi_image img{
    height: 150px;
}
.port_bottom_gct{
    /* background-color: red; */
    display: grid;
    padding-top: 20px;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    width: 100%;
    padding-top: 50px;
}