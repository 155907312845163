.LandingPage{
    width: 100%;
    background-color: #fff;
}
.LandingBox{
    height: 70vh;
    width: 100%;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

/******** Image Coursel  *************/

.ImageSlider{
    height: 70vh;
    width: 100%;
    
}
.Image_backgroundBox{
    height: 70vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.Image_backgroundBox section{
    height: 70vh;
    width: 100%;
    display: flex;
    background-color: rgba(0, 0, 0, 0.5);
}
.BOX_center{
    height: 100%;
    width: 90%;
    padding-top: 20vh;
    color: #fff;
}
.BOX_center h1{
    color: #fff;
    margin: 0;
    font-weight: 400;
    font-size: 40px;
}
.BOX_center .hlarge{
    font-weight: bold;
    font-size: 60px;
}
.BOX_center p{
    width: 56%;
}
.FA_left{
    height: 100%;
    width: 5%;
    display: grid;
    align-items: center;
    padding-left: 20px;
}
.FA_right{
    height: 100%;
    width: 5%;
    padding-right: 20px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}
.right-arrow{
    margin-top: 50%;
    font-size: 2.4rem;
    color: yellow;
    z-index: 20;
    cursor: pointer;
    user-select: none;
}
.left-arrow{
    margin-top: 50%;
    font-size: 2.4rem;
    color: yellow;
    z-index: 20;
    cursor: pointer;
    user-select: none;
}

/************************ ANIME ANIMATIONS ******************************/

.activeee .anime1{
    animation: anime1 1s ease forwards;
    position: relative;
}
@keyframes anime1{
    0%{top: 300px;}
    100%{top: 0px;}
}

.activeee .anime2{
    animation: anime2 1.2s ease forwards;
    position: relative;
}
@keyframes anime2{
    0%{top: 300px;}
    100%{top: 0px;}
}

.activeee .anime3{
    animation: anime3 1.5s ease forwards;
    position: relative;
}
@keyframes anime3{
    0%{top: 300px;}
    100%{top: 0px;}
}


/************************ ANIME ANIMATIONS ******************************/

.landing_container{
    padding-top: 30vh;
    padding-left: 30px;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
}
.bottom_h1{
    color: #fff;
    font-weight: 600;
    margin: 0;
    font-size: 50px;
}
.landing_container p{
    width: 450px;
    color: #fff;
}
.Aboutus_div{
    width: 90%;
    margin-left: 5%;
}
.aboutus_header{
    width: 100%;
    border: 1px;
    text-align: center;
    /* display: grid;
    align-items: center;
    justify-content: center; */
}
.aboutus_header h2{
    color: rgb(59, 59, 59);
}
.about_line{
    height: 2px;
    width: 100px;
    border-radius: 3px;
    background-color: rgb(59, 59, 59);
    opacity: 0.8;
}
.aboutus_about{
    width: 100%;
    margin-top: 20px;
}
.aboutus_about h3{
    color: rgb(59, 59, 59);
    font-size: 23px;
    margin-top: 10px;
    margin-bottom: 5px;
}
.aboutus_about p{
    font-weight: 600;
    text-align: justify;
}
.two_coins{
    width: 400px
}
.why_elite_Header{
    width: 100%;
}
.why_elite_Header h2{
    color: rgb(59, 59, 59);
}

.Utitlty_container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 30PX;
}
.Utility{
    padding-bottom: 20px;
    width: 200px;
    /* background-color: red; */
    display: grid;
    grid-template-columns: 50px 150px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 20px;
}
.ut_ri img{
    height: 30px;
}
.ut_lf h4{
    font-weight: 600;
    color: rgb(59, 59, 59);
    font-size: 19px;
    margin: 0;
}
.ut_lf p{
    font-weight: 600;
    margin: 0;
    color: rgb(134, 134, 134);
    margin-top: 7px;
}

/*//////////////// packages styling //////////////*/
.Step_container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.PackageDiv{
    padding-bottom: 10px;
    width: 80%;
    border: 1px solid gold;
    margin-top: 50px;
    border-radius: 10px;
}
.package_header{
    width: 100%;
    height: 110px;
    background-color: #f9f9f9;
    display: grid;
    justify-content: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.package_circle{
    height: 100px;
    width: 100px;
    border-radius: 60px;
    background-color: rgb(255, 71, 71);
    border: 5px solid #f9f9f9;
    margin-top: -40px;
    display: grid;
    align-items: center;
    justify-content: center;
}
.package_circle h3{
    color: #fff;
    margin: 0;
}
.package_circle h2{
    color: #fff;
    margin: 0;
}
.package_circle p{
    color: #fff;
    margin: 0;
}
.steps_conntainer{
    position: relative;
    top: -15px;
    display: grid;
    justify-content: center;
}
.steps_conntainer h2{
    color: rgb(59, 59, 59);
}
.Main_steps_container{
    width: 80%;
    margin-left: 10%;
    text-align: center;
}
.Main_steps_container p{
    font-size: 14px;
    color: rgb(71, 71, 71);
}
.investnow_container{
    height: 50px;
    width: 100%;
    display: grid;
    justify-content: center;
}
.investnow_btn{
    width: 120px;
    height: 40px;
    border: 2px solid gold;
    display: grid;
    justify-content: center;
    align-items: center;
}
.investnow_btn p{
    margin: 0;
    color: gold;
}
.Chart_section{
    width: 90%;
    margin-left: 5%;
    padding-bottom: 20px;
}
.chart_box{
    width: 90%;
    margin-left: 5%;
    height: 80px;
}
.chart_box h4{
    font-size: 17px;
    color: rgb(59, 59, 59);
    margin: 0;
    margin-bottom: 10px;
}
.chart_width{
    height: 20px;
    width: 100%;
    background-color: #eeeeee;
}
.chart_main{
    height: 100%;
    background-color: yellow;
}
.chart_main p{
    margin: 0;
    color: #fff;
    float: right;
    margin-right: 10px;
}

.ch_2{
    width: 100%;
    display: grid;
    justify-content: center;
}

.ch_2 img{
    max-width: 100%;
}
.investment_packages_container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.Team_container{
    margin-top: 50px;
}
.brain_container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.Brains{
    height: 380px;
    width: 80%;
    margin-top: 25px;
    border: 1px solid gold;
    overflow: hidden;
    border-radius: 8px;
}
.Brains:hover{
    box-shadow: 0px 0px 10px rgb(221, 221, 221);
}
.Brains_pic{
    height: 75%;
    width: 100%;
    text-align: center;
}
.Brains_pic img{
    height: 100%;
}
.Brain_details{
    height: 25%;
    width: 100%;
    text-align: center;
}
.Brain_details h3{
    margin: 0;
    font-weight: 400;
    color: rgb(59, 59, 59);
    margin-top: 20px;
    margin-bottom: 10px;
}
.Brain_details h4{
    margin: 0;
    font-weight: 600;
    color: rgb(114, 114, 114);
}
.Clients_container{
    height: 300px;
    padding-bottom: 20px;
    width: 90%;
    margin-left: 5%;
    margin-top: 20px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    overflow: hidden;
}
.Properties_div{
    height: 100%;
    width: 33%;
    display: grid;
    align-items: center;
    justify-content: center;
}
.properties_circle{
    height: 12px;
    width: 12px;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.158);
}
.Contact_main_container{
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 700px) {
    .Contact_main_container{
        grid-template-columns: repeat(1, 1fr);
    }
}
.sendMessage_container{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.Contact_info{
    width: 100%;
}
.Contact_info h3{
    opacity: 0.8;
    margin-top: 0;
}
.Info_mini_box{
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    opacity: 0.8;
    margin-bottom: 15px;
}
.Info_mini_box img{
    height: 20px;
}
.Info_mini_box p{
    font-size: 15px;
    margin-left: 10px;
}
.TextAreaInput{
    height: 150px;
    width: 100%;
    border: 1px solid cyan;
    margin-top: 15px;
    overflow: hidden;
    border-radius: 3px;
}
.TextAreaInput textarea{
    font-family: sans-serif;
    font-size: 17px;
    padding-top: 10px;
    padding-left: 20px;
}
.TextAreaInput textarea:focus{
    outline: none;
}
.sendMessage_button{
    height: 40px;
    width: 120px;
    background-color: #03255C;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-weight: 600;
    margin-top: 20px;
}
.Footer{
    height: 30px;
    padding-top: 70px;
    width: 100%;
    background-color: #fff;
    text-align: center;
}
.Footer p{
    margin: 0;
    opacity: 0.7;
}
.New_mini_box{
    width: 90%;
    border: 1.5px solid yellow;
    padding-top: 10px;
    padding-bottom: 15px;
    margin-bottom: 25px;
    border-radius: 4px;
    box-shadow: 0 0 7px rgb(233, 233, 233);
}
.New_mini_box:hover, .PackageDiv:hover{
    animation: scaleSt 0.6s ease forwards;
}
@keyframes scaleSt {
    100%{
        transform: scale(1.05);
    }
}
.NM_top{
    height: 50px;
    width: 100%;
    text-align: center;
}
.NM_top img{
    height: 45px;
}
.NM_middle{
    text-align: center;
}
.NM_middle h2{
    margin: 0;
    opacity: 0.8;
    margin-top: 10px;
}
.NM_bottom{
    width: 90%;
    margin-left: 5%;
}
.NM_bottom p{
    margin: 0;
    opacity: 0.6;
    margin-top: 10px;
}
.transist{
    width: 100%;
    text-align: center;
    animation: transit 1s ease forwards;
    position: relative;
}
@keyframes transit {
    0%{left: 50%;}
    100%{left: 0;}
}
.CT_top{
    height: 85px;
    width: 100%;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
}
.CT_top img{
    height: 85px;
    width: 85px;
    border-radius: 50px;
}
.CT_middle{
    width: 80%;
    margin-left: 10%;
    color: #fff;
    text-align: center;
}
.CT_bottom{
    width: 100%;
    text-align: center;
    color: #fff;
}
.CT_colors{
    height: 50px;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}
.Read_More_button{
    height: 50px;
    width: 150px;
    background-color: #03255C;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    display: grid;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
}
.Read_More_button p{
    color: #fff;
    margin: 0;
    padding: 0;
}
.Read_More_button p:hover{
    color: yellow;
}

.AwesomeFactsContainer{
    padding-bottom: 50px;
    width: 90%;
    margin-left: 5%;
    border: 1px solid yellow;
    border-radius: 10px;
    box-shadow: 0 0 7px rgb(233, 233, 233);
    text-align: center;
}

.AwesomeFactsContainer h3{
    color: #000;
    font-weight: 400;
}

.AwesomeFactsContainer h1{
    color: rgb(240, 240, 0);
    font-weight: bold;
}
.AwesomeFactsContainer p{
    color: #000;
    font-size: 16px;
}

.AwesomeFacts{
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    margin-top: 30px;
}
.AwesomeFacts p{
    font-weight: 600;
}

.AwesomeNumbers{
    display: flex;
    flex-direction: row;
    width: fit-content;
    text-align: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}
.AwesomeNumbers p{
    color: #000;
    font-size: 600;
    margin: 0;
    padding: 0;
    padding-top: 4px;
}
.AwesomeNumbers p span {
    color: rgb(240, 240, 0);
    font-size: 30px;
    font-weight: bold;
}

.AwesomeFactsContainer img{
    height: 80px;
    margin-top: 40px;
    animation: rocket 2s
    ease-out
    0s
    alternate
    infinite
    none
    running;
}

@keyframes rocket{
    0%{
        transform: translateX(-30px);
        transform: translateY(-30px);
    }
    100%{
        transform: translateX(0px);
        transform: translateY(0px);
    }
}