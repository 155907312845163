.AboutUsPage{
    width: 100vw;
    background: linear-gradient(180deg, rgba(4, 161, 255, 0.06) 0%, rgba(4, 161, 255, 0) 100%);
}
.Aboutus_div_about{
    max-width: 1000px;
    padding: 0 20px;
    box-sizing: border-box;
    margin-right: auto;
    margin-left: auto;
}
.about_us_about_introtext{
    max-width: 600px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
}
.EC_header{
    font-weight: 600;
    color: #000;
    margin-top: 50px;
}
.sc_{
    max-width: 1000px;
    padding: 0 20px;
    box-sizing: border-box;
    margin-right: auto;
    margin-left: auto;
}
.View_cert{
    height: 50px;
    width: 170px;
    background-color: #03255C;
    margin-top: 20px;
    display: grid;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
}

.View_cert p{
    color: #fff;
    margin: 0;
    padding: 0;
}
.View_cert p:hover{
    color: yellow;
}
.CDFG{
    margin-top: 40px;
}
.CertificateDiv{
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    display: grid;
    align-items: center;
    justify-content: center;
}
.CertificateDiv img{
    max-width: 100%;
}