.container{
    background-color: #fff;
    margin-right: auto;
    margin-left: auto;
    max-width: 600px;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    border: 0.5px solid #e4e4e4;
}
.top{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
.icon{
    height: 35px;
    width: 35px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffc823;
    background-color: var(--purple);
    font-size: 16px;
}
.boxTitle{
    font-size: 18px;
    font-weight: 500;
    margin: 0;
}
.inputContainer{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
}
.input{
    width: 100%;
}
.input input, .selectBox{
    width: 100%;
    height: 45px;
    padding-left: 10px;
    box-sizing: border-box;
    border-radius: 7px;
    border: 0.5px solid #e2e2e2;
    background-color: #f5f5f5;
    font-size: 15px;
    font-weight: 500;
}
.input input:focus, .selectBox:focus{
    outline: none;
    border-color: var(--purple);
}
.flexInputs{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    align-items: center;
}
@media (max-width: 700px) {
    .flexInputs{
        grid-template-columns: repeat(1, 1fr);
    }
}
.submit{
    max-width: 200px;
    height: 50px;
    color: #fff;
    background-color: var(--purple);
    border-radius: 10px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
    font-weight: 500;
    cursor: pointer;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}