.container{
    width: 100vw;
    padding: 50px 20px;
    box-sizing: border-box;
    text-align: center;
    padding-bottom: 50px;
}
.container h2{
    margin: 0;
    font-weight: 600;
    margin-bottom: 30px;
}
.main{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
}
.main img{
    max-height: 35px;
    opacity: 0.7;
}