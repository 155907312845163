.container{
    margin-top: 20px;
    width: 100%;
}
.container h3{
    font-size: 18px;
    font-weight: 600;
    color: #000;
    margin-bottom: 30px;
}
.balanceBoxContainer{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    align-items: center;
    margin-bottom: 10px;
}
.other{
    width: 100%;
    margin-top: 30px;
}
@media (max-width: 1100px) {
    .balanceBoxContainer{
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (max-width: 800px) {
    .balanceBoxContainer{
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (max-width: 400px) {
    .balanceBoxContainer{
        grid-template-columns: repeat(1, 1fr);
    }
}